
const API = 'https://api.frttour.com';
// const API = "http://localhost:62672";

export const environment = {
  production: true,
  API: API,
  ambiente: "ARKBEDS",
  THEME: "ARKBEDS", //(FRT - ARKBEDS - FRT-BLACK)
  //Módulos de turismo
  USA_CASAMENTO: true,
  USA_CONTRATO: false,
  USA_CIRCUITO: false,
  USA_TURISMO: true,
  USA_PACOTE: false,
  USA_SEGURO: false,
  USA_DISNEY: true,
  USA_CARRO: true,
  USA_AEREO: false,
  USA_LOJA: false,
  USA_CASA: true,
  USA_HOTEL: true,
  USA_BETO: false,
  USA_PROMOCIONAL: false,
  USA_MARKUPIMPRESSAO: true,
  //Apis dos módulos de turismo
  TURISMO: API,
  TURISMO_AEREO: API,
  TURISMO_HOTEL: API,
  TURISMO_SERVICO: API,
  TURISMO_SEGURO: API,
  GERENCIAMENTO: API,
  TURISMO_CARRO: API,
  EMPRESAS: API,

  //Módulos Adicionais
  USA_BLOQUEIO_FRT: false, // Permite o uso de um botão adicional na aba bloqueios
  USA_INTRANET: true, // Permite o uso da Intranet no portal
  USA_LAMINAS: false, // Permite Gerar Lâminas no portal
  USA_BANNERS: false, //Permite o uso de Banners

  //Configurações
  MAPBOX_ACCESSTOKEN: 'pk.eyJ1IjoiZnJ0b3BlcmFkb3JhIiwiYSI6ImNsN3E5a3N0YTAycm00MW8wd2J5NXFoMjIifQ.NDfY7OK_ygHR567e-TVVcw',
  TAG_GOOGLE: "",
  USA_PACOTESIMPLIFICADO: false,
  CLIENT_ID: "postman:123",
  ITENSPORPAGINA: 20,
  MAXIMO_QUARTOS: 8,
  URLSITE: "https://arkbeds.com/Cadastrar",
  PAYPAL_CLIENT_ID: "Ab66uq-fSYRSlv7qqAtV3lTnYYvKZrU9dy5R8JNaB3iU4QLxuNH4uobcml6gDlwZhXrAoPAKnD2gBZIR"
};
